@use './variables' as var;

// @use './highcharts';

:root {
  &.dark {
    --tooltip-header: hsl(var(--primary-h), 2%, 75%);
  }
  &.light {
    --tooltip-header: hsl(var(--primary-h), 2%, 45%);
  }
}

.highcharts-plot-line-label {
  text-shadow:
    0 0 5px var(--background),
    0 0 10px var(--background),
    0 0 15px var(--background),
    0 0 20px var(--background),
    0 0 30px var(--background),
    0 0 40px var(--background),
    0 0 55px var(--background),
    0 0 75px var(--background);
  fill: var(--textColor);
}

.highcharts-background {
  fill: transparent !important;
}
.highcharts-loading {
  background-color: color-mix(in srgb, var(--background), transparent 60%) !important;
}

.highcharts-tooltip-container {
  transform: none !important;
}
.highcharts-tooltip-box {
  fill: var(--panelBack);
}
.highcharts-tooltip span {
  color: var(--textColor);
}
.highcharts-tooltip {
  @media only print {
    display: none;
  }
  table {
    tr[header],
    tr.header {
      th {
        text-align: left;
        font-size: 1rem;
      }
      th,
      td {
        padding-bottom: 0.4rem;
      }
    }
    tr[footer],
    tr.footer {
      th,
      td {
        border-top: 1px solid var(--grey);
      }
    }
    th {
      color: var(--tooltip-header);
      text-align: right;
      font-weight: bold;
    }
    td {
      color: var(--textColor);
    }
    td,
    th {
      line-height: 1em;
    }
    .line {
      border-bottom: 1px solid var(--borderColor);
    }
    .number {
      font-family: 'ABCDiatype Mono';
      text-align: right;
    }
    .bullet {
      font-size: 1.5rem;
      line-height: 0.5rem;
      display: flex;
      place-items: baseline;
      margin-left: -0.2rem;
    }
  }
}

.highcharts-container {
  // @media only screen {
  width: 100% !important;
  height: 100% !important;
  .highcharts-root {
    font-family: var.$fontFamily !important;
  }
  svg {
    max-height: 100%;
    width: 100%;
  }
  // }
}

.highcharts-axis-labels {
  color: var(--muteText);
}
.highcharts-axis {
  color: var(--textColor);
}

.highcharts-axis-labels,
.highcharts-axis {
  text {
    color: inherit !important;
    fill: currentColor !important;
  }
}

.highcharts-label.highcharts-data-label > text {
  fill: var(--textColor) !important;
  stroke: transparent !important;
  tspan {
    fill: var(--textColor) !important;
    stroke: var(--background) !important;
  }
}
.highcharts-series-group .highcharts-series.highcharts-column-series path {
  stroke: var(--borderColor);
}

.highcharts-column-series rect {
  stroke: var(--borderColor);
}

.highcharts-yaxis-grid path {
  stroke: var(--borderColor);
}

.highcharts-legend-item {
  text {
    fill: var(--textColor) !important;
  }
}

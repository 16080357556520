// Remove shadow from plot line labels.
// This causes a huge performance hit when rendering labels on plotlines.
// Replace with a text shadow that is not as heavy on the browser.
.highcharts-plot-line-label {
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.dark {
  .highcharts-plot-line-label {
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  }
}


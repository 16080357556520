@use '@angular/material' as mat;
@use './variables';

// $config: mat.get-theme-typography();

.responsive-table {
  max-width: 100vw;
  // height: calc(100% - 0.5rem);
  overflow: auto;
  td {
    overflow: hidden;
  }
}
.mat-mdc-cell {
  color: var(--textColor);
}
.mat-mdc-header-cell,
.mat-mdc-footer-cell {
  color: hsl(var(--textColor-h), var(--textColor-s), var(--muteText-l));
  small {
    padding-left: 0.5ch;
  }
}
.mat-mdc-row {
  &:nth-child(even) td.mat-mdc-cell {
    background: color-mix(in srgb, var(--background), rgb(from var(--textColor) r g b / 0.87) 3%);
  }
  td.mat-mdc-cell {
    border-color: rgb(from var(--borderColor) r g b / 0.3);
  }
}
.mat-mdc-header-row,
.mat-mdc-footer-row,
th.mat-mdc-header-cell,
td.mat-mdc-footer-cell {
  border-color: var(--borderColor);
}
.mat-mdc-header-cell,
.mat-mdc-cell,
.mat-mdc-footer-cell {
  background: var(--panelBack);
  padding-right: 6px;
  &:not(:first-child) {
    padding-left: 6px;
  }
}
.mat-mdc-cell,
.mat-mdc-footer-cell {
  &.number,
  &.date,
  &.numeric {
    font-family: 'ABCDiatype Mono';
  }
}
.mat-mdc-cell,
.mat-mdc-header-cell,
.mat-mdc-footer-cell {
  // Alignment
  &.right,
  &.right .table-cell-wrapper {
    text-align: right;
    padding-right: 10px;
    place-content: flex-end;
  }
  &.center,
  &.center .table-cell-wrapper {
    text-align: center;
    place-content: center;
  }
  // Number alignment
  &.number,
  &.number .table-cell-wrapper {
    text-align: right;
    justify-content: flex-end;
    align-content: center;
    .mat-sort-header-container {
      place-items: center;
    }
  }
  &.number + *:not(.number),
  &.number + *:not(.number) .table-cell-wrapper {
    padding-left: 10px;
  }
  &.number .mat-sort-header-container {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: right !important;
  }
  // Coloring
  &.negative .table-cell-wrapper {
    color: var(--warning);
  }
  &.positive .table-cell-wrapper {
    color: var(--success);
  }
  // Spacing
  &:first-child {
    padding-left: 24px;
  }
}
.mat-mdc-footer-row {
  background: var(--panelBack);
  &.lined .mat-mdc-footer-cell {
    border-top: 1px solid var(--borderColor);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.9em;
    font-weight: 500;
    border-bottom-style: double;
    border-bottom-width: 5px;
  }
  .mat-subheading-1,
  th {
    // @include mat-typography-level-to-styles($config, subheading-1);
    text-align: left;
    margin: 0;
  }
}

.graph-connected tbody tr:hover,
tr.active {
  @media only screen {
    td {
      background-color: var(--bgGrey);
    }
  }
}

.mat-mdc-table[matsort] td {
  padding-right: 1.2rem;
}

.mat-mdc-table {
  background: var(--panelBack);
}
.mat-mdc-table tr.mat-mdc-row {
  @media print {
    height: 28px;
    td {
      border-bottom-style: dotted;
    }
  }
  // Support stretched links. This will stretch across the entire table
  &.mat-mdc-row {
    position: relative;
    .row-link a {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        pointer-events: auto;
      }
      &:hover::after {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
// Safari will not set table-rows to relative positioning. Disable stretched links for Safari.
:root .UA_Safari .mat-mdc-table tr.mat-mdc-row {
  .row-link {
    position: relative;
    a:hover::after {
      background-color: transparent;
    }
  }
}

.mat-sort-header-content {
  gap: 3px;
}

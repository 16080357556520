// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'sass:map';
@use 'sass:color' as color;
@use '@angular/material' as mat;

// Plus imports for other components in your app.
@use './variables' as var;
@use './functions';

@font-face {
  font-family: 'ABCDiatype';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/ABCDiatype-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'ABCDiatype';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/ABCDiatype-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'ABCDiatype Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/ABCDiatypeMono-Regular.woff2') format('woff2');
}
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.
$light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      use-system-variables: true,
      system-variables-prefix: sys,
    ),
    typography: (
      use-system-variables: true,
      system-variables-prefix: sys,
    ),
  )
);
$dark-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      use-system-variables: true,
      system-variables-prefix: sys,
    ),
    typography: (
      use-system-variables: true,
      system-variables-prefix: sys,
    ),
  )
);
@include mat.elevation-classes();
@include mat.app-background();

:root {
  color-scheme: light dark;
  supported-color-schemes: light dark;
  --dark-l: 2%;
  --grey-s: 0%;
  --light-l: 92%;
  --primary-h: #{color.channel(var.$primary, 'hue', hsl)};
  --primary-s: #{color.channel(var.$primary, 'saturation', hsl)};
  --primary: hsl(var(--primary-h), var(--primary-s), var(--primary-l));
  --primaryLight: hsl(var(--primary-h), var(--primary-s), var(--primaryLight-l));
  --primaryText: var(--buttonText); // Used only for login screen. Light text on dark background.
  --mat-table-header-headline-font: var(--baseFont);
  --mat-table-row-item-label-text-font: var(--baseFont);
  --mat-form-field-container-text-font: var(--baseFont);

  // --accent-h: 57deg;
  --accent-h: #{color.channel(var.$accent, 'hue', hsl)};
  --accent-s: #{color.channel(var.$accent, 'saturation', hsl)};
  --accent: hsl(var(--accent-h), var(--accent-s), var(--accent-l));
  --accentContrast: hsl(var(--accent-h), var(--accent-s), var(--dark-l));
  --accentLight: hsl(var(--accent-h), var(--accentLight-s), var(--accentLight-l));

  // Second accent for special elements
  --special-h: #{color.channel(var.$special, 'hue', hsl)};
  --special-l: #{color.channel(var.$special, 'lightness', hsl)};
  --special-s: #{color.channel(var.$special, 'saturation', hsl)};
  --special: hsl(var(--special-h), var(--special-s), var(--special-l));

  // Different backgrounds
  --background: hsl(var(--primary-h), 8%, var(--background-l));
  --bgContrast: hsl(var(--primary-h), var(--primary-s), var(--contrast-l));
  --borderColor: hsla(var(--primary-h), var(--grey-s), var(--borderColor-l), 75%);
  --buttonFace: var(--primary);
  --buttonFaceLit: hsl(var(--primary-h), var(--primary-s), var(--buttonFaceLit-l));
  --toggleButtonFace: hsl(var(--primary-h), var(--primary-s, var(--toggleButtonFace-l)));
  --toggleButtonFaceLit: hsl(var(--primary-h), var(--primary-s), var(--toggleButtonFaceLit-l));
  --inverseBackground: hsl(var(--primary-h), 100%, var(--textColor-l));
  --listItem: hsl(var(--primary-h), var(--grey-s), var(--listItem-l));
  --listItemLit: hsl(var(--primary-h), var(--grey-s), var(--listItemLit-l));
  --panelBack: hsl(var(--primary-h), 4%, var(--panelBack-l));
  --panelBackLit: hsl(var(--primary-h), 4%, calc(var(--panelBackLit-l)));
  --panelHead: hsl(var(--primary-h), var(--primary-s), var(--panelHead-l));
  --disabledBack: hsl(var(--primary-h), 8%, var(--variation-l));

  // Font & text colors
  --accentButtonText: hsl(var(--textColor-h), var(--textColor-s), var(--accentButtonText-l));
  --accentText: hsl(var(--accent-h), var(--accent-s), var(--accentText-l));
  --baseFont: #{var.$baseFont};
  --baseFontSize: 16px;
  --buttonDisabled: hsl(var(--textColor-h), var(--textColor-s), var(--buttonDisabled-l));
  --buttonDisabledLight: hsla(var(--textColor-h), var(--textColor-s), var(--buttonDisabled-l), 0.7);
  --buttonMute: hsl(var(--textColor-h), var(--textColor-s), var(--buttonMute-l));
  --toggleButtonText: hsl(var(--textColor-h), var(--textColor-s), var(--toggleButtonText-l));
  --buttonText: hsl(var(--textColor-h), var(--textColor-s), var(--buttonText-l));
  --muteText: hsl(var(--textColor-h), var(--textColor-s), var(--muteText-l));
  --option: hsl(var(--textColor-h), var(--textColor-s), var(--option-l));
  --textColor-h: var(--primary-h);
  --textColor-s: var(--grey-s);
  --textColor: hsl(var(--textColor-h), var(--textColor-s), var(--textColor-l));
  --disabledText: var(--muteText);

  // Neutral grey tones
  --bgGrey: hsl(var(--primary-h), 4%, var(--variation-l));
  --grey: hsl(var(--primary-h), 2%, 45%);
  --greyHover: hsl(var(--primary-h), 2%, var(--hover-l));
  --lightGrey: hsl(var(--primary-h), 2%, var(--buttonFaceLit-l));

  // Officemap
  --mapBack: var(--bgGrey);
  --mapDark: hsl(var(--primary-h), 2%, var(--mapDark-l));
  --mapFilled: hsl(var(--primary-h), 2%, var(--mapFilled-l));
  --mapLight: hsl(var(--primary-h), 2%, var(--mapLight-l));
  --mapOutline: hsl(var(--primary-h), var(--primary-s), var(--option-l));
  --mapPrimary: hsl(192, 76%, 23%);
  --mapShadow: rgb(108, 123, 127);
  --mapText: var(--textColor);

  --lightSuccess: #{color.adjust(var.$success, $alpha: -0.7)};
  --lightWarning: #{color.adjust(var.$warning, $alpha: -0.7)};
  --outline: var(--primary);
  --success: #{var.$success};
  --warning: #{var.$warning};

  --borderRadius: 4px;
  &:has(.with-rail) {
    --borderRadius: 0.5rem;
  }

  @include mat.elevation-classes();
  @include mat.app-background();

  &.light {
    color-scheme: light;
    // @include mat.all-component-colors($light-theme);
    // Overrides for light mode
    --accentButtonText-l: #{getTextColor(var.$accent, 86%, 16%)};
    --accentInverse-l: #{getTextColor(var.$accent, 16%, 86%)};
    --accent-l: 72%;
    --accentLight-l: 76%;
    --accentLight-s: var(--accent-s);
    --accentText-l: #{getTextColor(var.$accent, 46%, 36%)};
    --background-l: 100%;
    --contrast-l: 9%;
    --primary-l: 12%;
    --primaryLight-l: 38%;
    --variation-l: 95%;

    --borderColor-l: 77%;
    --buttonFace-l: 12%;
    --buttonFaceLit-l: 5%;
    --toggleButtonFace-l: 82%;
    --toggleButtonFaceLit-l: 92%;
    --hover-l: 89%;
    --listItem-l: 99%;
    --listItemLit-l: 100%;
    --panelBack-l: 99%;
    --panelBackLit-l: 90%;
    --panelHead-l: 20%;

    --buttonDisabled-l: 65%;
    --buttonMute-l: 85%;
    --buttonText-l: 99%;
    --toggleButtonMute-l: 30%;
    --toggleButtonText-l: 19%;
    --mapDark-l: 85%;
    --mapFilled-l: 99%;
    --mapLight-l: 77%;
    --muteText-l: 68%;
    --option-l: 20%;
    --successWarn-l: 76%;
    --successWarn-s: 50%;
    --textColor-l: 2%;

    @include mat.elevation-classes();
    @include mat.app-background();

    // Override for chameleon/rail
    &:has(.with-rail) {
      --background-l: 94%;
      --panelBack-l: 100%;
    }
  }
  &.dark {
    color-scheme: dark;
    // @include mat.all-component-colors($dark-theme);
    // Overrides for dark mode
    --accentButtonText-l: #{getTextColor(var.$accent, 86%, 16%)};
    --accentInverse-l: #{getTextColor(var.$accent, 16%, 86%)};
    --accent-l: 62%;
    --accentLight-l: 36%;
    --accentLight-s: 35%;
    --accentText-l: #{getTextColor(var.$accent, 86%, 56%)};
    --background-l: 7%;
    --contrast-l: 92%;
    --primary-l: 19%;
    --primaryLight-l: 34%;
    --variation-l: 25%;

    --borderColor-l: 38%;
    --buttonFace-l: 20%;
    --buttonFaceLit-l: 30%;
    --toggleButtonFace-l: 20%;
    --toggleButtonFaceLit-l: 30%;
    --hover-l: 22%;
    --listItem-l: 20%;
    --listItemLit-l: 40%;
    --panelBack-l: 15%;
    --panelBackLit-l: 30%;
    --panelHead-l: 30%;

    --buttonDisabled-l: 55%;
    --buttonMute-l: 65%;
    --buttonText-l: 99%;
    --toggleButtonMute-l: 65%;
    --toggleButtonText-l: 99%;
    --mapDark-l: 25%;
    --mapFilled-l: 49%;
    --mapLight-l: 77%;
    --muteText-l: 58%;
    --option-l: 85%;
    --successWarn-l: 34%;
    --successWarn-s: 30%;
    --textColor-l: 98%;

    @include mat.elevation-classes();
    @include mat.app-background();
  }
}

// Material design overrides
// prettier-ignore
:root {
  --mat-autocomplete-background-color: var(--panelBack);
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-container-shape: 4px;
  --mat-badge-background-color: #005cbb;
  --mat-badge-container-offset: -12px 0;
  --mat-badge-container-overlap-offset: -12px;
  --mat-badge-container-padding: 0 4px;
  --mat-badge-container-shape: 9999px;
  --mat-badge-container-size: 16px;
  --mat-badge-disabled-state-background-color: rgba(0, 92, 187, .38);
  --mat-badge-disabled-state-text-color: #var(--muteText);
  --mat-badge-legacy-container-size: 16px;
  --mat-badge-text-color: var(--muteText);
  --mat-badge-text-size: .688rem;
  --mat-card-subtitle-text-line-height: 1.4rem;
  --mat-card-subtitle-text-size: 0.8rem;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 400;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-checkbox-label-text-color: var(--textColor);
  --mat-checkbox-touch-target-display: block;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-selected-disabled-trailing-icon-color: var(--primary);
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-icon-color: var(--primary);
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-datepicker-calendar-body-label-text-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.54);
  --mat-datepicker-calendar-container-background-color: var(--panelBack);
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-date-disabled-state-text-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.38);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(33, 34, 36, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(33, 34, 36, 0.3);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(33, 34, 36, 0.2);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-preview-state-outline-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.24);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(33, 34, 36, 0.4);
  --mat-datepicker-calendar-date-selected-state-background-color: #212224;
  --mat-datepicker-calendar-date-text-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.87);
  --mat-datepicker-calendar-date-text-color: var(--textColor);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: hsla( var(--primary-h), 8%, var(--textColor-l), 0.18);
  --mat-datepicker-calendar-date-today-outline-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.38);
  --mat-datepicker-calendar-header-divider-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.12);
  --mat-datepicker-calendar-header-text-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.54);
  --mat-datepicker-calendar-header-text-color: var(--textColor);
  --mat-datepicker-calendar-navigation-button-icon-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.54);
  --mat-datepicker-calendar-period-button-icon-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.54);
  --mat-datepicker-calendar-period-button-text-color: var(--textColor);
  --mat-datepicker-range-input-disabled-state-separator-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.38);
  --mat-datepicker-range-input-separator-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.87);
  --mat-datepicker-toggle-active-state-icon-color: #212224;
  --mat-datepicker-toggle-icon-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.54);
  --mat-divider-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.12);
  --mat-divider-width: 1px;
  --mat-expansion-container-background-color: var(--panelBack);
  --mat-expansion-container-text-color: var(--textColor);
  --mat-expansion-header-text-line-height: 2rem;
  --mat-expansion-header-text-size: 1.1rem;
  --mat-expansion-header-text-weight: 500;
  --mat-fab-foreground-color: var(--buttonText);
  --mat-fab-foreground-color: var(--buttonText);
  --mat-filled-button-disabled-state-layer-color: hsl(var(--primary-h), 8%, var(--primary-l));
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-icon-offset: -4px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mat-filled-button-ripple-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.1);
  --mat-filled-button-state-layer-color: hsl(var(--primary-h), 8%, var(--primary-l));
  --mat-form-field-container-height: 48px;
  --mat-form-field-container-text-line-height: 1.9rem;
  --mat-form-field-filled-with-label-container-padding-bottom: 0;
  --mat-form-field-filled-with-label-container-padding-top: 18px;
  --mat-form-field-state-layer-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.04);
  --mat-list-list-item-leading-icon-end-space: 8px;
  --mat-mdc-fab-color: var(--buttonText);
  --mat-menu-container-color: var(--panelBack);
  --mat-menu-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.12);
  --mat-menu-item-icon-color: var(--textColor);
  --mat-menu-item-icon-size: 28px;
  --mat-menu-item-label-text-color: var(--textColor);
  --mat-menu-item-spacing: 8px;
  --mat-menu-item-with-icon-leading-spacing: 8px;
  --mat-optgroup-label-text-color: var(--textColor);
  --mat-option-label-text-color: var(--textColor);
  --mat-option-selected-state-label-text-color: var(--textColor);
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-offset: -4px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-offset: -4px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-disabled-trigger-text-color: var(--muteText);
  --mat-select-enabled-arrow-color: var(--textColor);
  --mat-select-enabled-trigger-text-color: var(--textColor);
  --mat-select-panel-background-color: var(--panelBack);
  --mat-select-trigger-text-line-height: 28px;
  --mat-standard-button-toggle-background-color: var(--toggleButtonFace);
  --mat-standard-button-toggle-disabled-state-background-color: var(--background);
  --mat-standard-button-toggle-disabled-state-text-color: var(--disabledText);
  --mat-standard-button-toggle-divider-color: var(--borderColor);
  --mat-standard-button-toggle-height: 36px;
  --mat-standard-button-toggle-selected-state-background-color: var(--toggleButtonFaceLit);
  --mat-standard-button-toggle-selected-state-text-color: var(--toggleButtonText);
  --mat-standard-button-toggle-text-color: var(--toggleButtonMute);
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-track-outline-width: 1px;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-with-icon-handle-size: 20px;
  --mat-tab-header-active-focus-indicator-color: var(--accent);
  --mat-tab-header-active-focus-label-text-color: var(--muteText);
  --mat-tab-header-active-hover-indicator-color: var(--accent);
  --mat-tab-header-active-hover-label-text-color: var(--muteText);
  --mat-tab-header-active-label-text-color: var(--muteText);
  --mat-tab-header-active-ripple-color: var(--muteText);
  --mat-tab-header-disabled-ripple-color: hsla(var(--textColor-h), var(--textColor-s), var(--textColor-l), 0.38);
  --mat-tab-header-inactive-focus-label-text-color: hsla( var(--textColor-h), var(--textColor-s), var(--textColor-l), 0.6);
  --mat-tab-header-inactive-hover-label-text-color: hsla( var(--textColor-h), var(--textColor-s), var(--textColor-l), 0.6);
  --mat-tab-header-inactive-label-text-color: hsla(var(--textColor-h), var(--textColor-s), var(--textColor-l), 0.6);
  --mat-tab-header-inactive-ripple-color: var(--muteText);
  --mat-tab-header-pagination-icon-color: var(--textColor);
  --mat-table-background-color: var(--panelBack);
  --mat-table-footer-supporting-text-line-height: 1.125rem;
  --mat-table-footer-supporting-text-size: 1rem;
  --mat-table-footer-supporting-text-tracking: normal;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-header-headline-line-height: 1.4rem;
  --mat-table-header-headline-size: 0.8rem;
  --mat-table-header-headline-tracking: normal;
  --mat-table-header-headline-weight: 400;
  --mat-table-row-item-container-height: 36px;
  --mat-table-row-item-label-text-line-height: 1.125rem;
  --mat-table-row-item-label-text-size: 0.9rem;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-row-item-label-text-weight: 400;
  --mat-text-button-disabled-state-layer-color: hsl(var(--primary-h), 8%, var(--primary-l));
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-icon-offset: 0;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mat-text-button-ripple-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.1);
  --mat-text-button-state-layer-color: hsl(var(--primary-h), 8%, var(--primary-l));
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-toolbar-container-text-color: var(--textColor);
  --mat-toolbar-standard-height: 64px;
  --mat-tree-node-text-color: var(--textColor);
  --mdc-checkbox-selected-checkmark-color: var(--background);
  --mdc-checkbox-selected-focus-icon-color: var(--accent);
  --mdc-checkbox-selected-hover-icon-color: var(--accent);
  --mdc-checkbox-selected-icon-color: var(--accent);
  --mdc-checkbox-selected-pressed-icon-color: var(--accent);
  --mdc-checkbox-state-layer-size: 40px;
  --mdc-checkbox-unselected-focus-icon-color: var(--borderColor);
  --mdc-checkbox-unselected-hover-icon-color: var(--borderColor);
  --mdc-checkbox-unselected-icon-color: var(--borderColor);
  --mdc-checkbox-unselected-pressed-icon-color: var(--borderColor);
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-disabled-label-text-color: var(--primary);
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-focus-state-layer-color: hsl(var(--primary-h), 8%, var(--primary-l));
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-hover-state-layer-color: hsl(var(--primary-h), 8%, var(--primary-l));
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-label-text-color: var(--primary);
  --mdc-chip-outline-color: transparent;
  --mdc-chip-outline-width: 0;
  --mdc-chip-selected-focus-state-layer-color: hsl(var(--primary-h), 8%, var(--primary-l));
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-hover-state-layer-color: hsl(var(--primary-h), 8%, var(--primary-l));
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-selected-label-text-color: var(--primary);
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-color: var(--primary);
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mdc-chip-with-icon-icon-color: var(--primary);
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-with-icon-selected-icon-color: var(--primary);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--primary);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--primary);
  --mdc-circular-progress-active-indicator-color: var(--primary);
  --mdc-dialog-supporting-text-color: var(--textColor);
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: var(--buttonFace);
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-color: var(--buttonText);
  --mdc-radio-state-layer-size: 24px;
  --mdc-filled-button-container-color: var(--buttonFace);
  --mdc-filled-button-container-height: 36px;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-container-shape: var(--borderRadius);
  --mdc-filled-button-disabled-container-color: var(--buttonDisabled);
  --mdc-filled-button-disabled-label-text-color: var(--buttonText);
  --mdc-filled-button-keep-touch-target: false;
  --mdc-filled-button-label-text-color: var(--buttonText);
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-caret-color: var(--muteText);
  --mdc-filled-text-field-container-shape: var(--borderRadius);
  --mdc-filled-text-field-focus-active-indicator-color: var(--textColor);
  --mdc-filled-text-field-focus-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-label-text-color: var(--textColor);
  --mdc-filled-text-field-hover-active-indicator-color: var(--muteText);
  --mdc-filled-text-field-hover-label-text-color: hsl(from var(--textColor) h s var(--contrast));
  --mdc-form-field-label-text-color: var(--textColor);
  --mdc-icon-button-disabled-icon-color: var(--muteText);
  --mdc-icon-button-disabled-icon-color: var(--muteText);
  --mdc-icon-button-icon-color: var(--textColor);
  --mdc-linear-progress-active-indicator-color: var(--accent);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 4px;
  --mdc-list-list-item-focus-label-text-color: var(--textColor);
  --mdc-list-list-item-hover-label-text-color: var(--textColor);
  --mdc-list-list-item-hover-leading-icon-color: var(--muteText);
  --mdc-list-list-item-label-text-color: var(--option);
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-one-line-container-height: 42px;
  --mdc-list-list-item-supporting-text-color: var(--textColor);
  --mdc-outlined-button-container-height: 36px;
  --mdc-outlined-button-container-shape: 4px;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-label-text-color: var(--textColor);
  --mdc-outlined-button-outline-color: var(--borderColor);
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-disabled-label-text-color: var(--muteText);
  --mdc-outlined-button-disabled-outline-color: var(--buttonMute);
  --mdc-plain-tooltip-container-color: var(--panelBack);
  --mdc-protected-button-container-color: var(--buttonFace);
  --mdc-protected-button-container-height: 36px;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-shape: var(--borderRadius);
  --mdc-protected-button-disabled-container-color: var(--buttonDisabled);
  --mdc-protected-button-disabled-label-text-color: var(--muteText);
  --mdc-protected-button-keep-touch-target: false;
  --mdc-protected-button-label-text-color: var(--buttonText);
  --mdc-secondary-navigation-tab-container-height: 42px;
  --mdc-snackbar-container-color: var(--primary);
  --mdc-snackbar-container-color: var(--primary);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-supporting-text-color: var(--buttonText);
  --mdc-snackbar-supporting-text-color: var(--buttonText);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.38);
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shadow-color: hsl(var(--primary-h), 8%, var(--primary-l));
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-focus-handle-color: #7bc8f4;
  --mdc-switch-selected-focus-handle-color: var(--primary);
  --mdc-switch-selected-focus-state-layer-color: #7bc8f4;
  --mdc-switch-selected-focus-state-layer-color: var(--primary);
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-focus-track-color: #4dbeff;
  --mdc-switch-selected-focus-track-color: var(--primaryLight);
  --mdc-switch-selected-handle-color: #7bc8f4;
  --mdc-switch-selected-handle-color: var(--primary);
  --mdc-switch-selected-hover-handle-color: #7bc8f4;
  --mdc-switch-selected-hover-handle-color: var(--primary);
  --mdc-switch-selected-hover-state-layer-color: #7bc8f4;
  --mdc-switch-selected-hover-state-layer-color: var(--primary);
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-hover-track-color: #4dbeff;
  --mdc-switch-selected-hover-track-color: var(--primaryLight);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-selected-pressed-handle-color: #7bc8f4;
  --mdc-switch-selected-pressed-handle-color: var(--primary);
  --mdc-switch-selected-pressed-state-layer-color: #7bc8f4;
  --mdc-switch-selected-pressed-state-layer-color: var(--primary);
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-pressed-track-color: #4dbeff;
  --mdc-switch-selected-pressed-track-color: var(--primaryLight);
  --mdc-switch-selected-track-color: #4dbeff;
  --mdc-switch-selected-track-color: var(--primaryLight);
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-tab-indicator-active-indicator-color: var(--accent);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-text-button-container-height: 36px;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-disabled-label-text-color: hsla(var(--primary-h), 8%, var(--textColor-l), 0.38);
  --mdc-text-button-keep-touch-target: false;
  --mdc-text-button-label-text-color: var(--textColor);
  --mdc-theme-on-surface: var(--textColor);
  --mdc-theme-primary: var(--primaryLight);
  --mdc-theme-surface: var(--panelBack);
  --mdc-theme-text-primary-on-background: var(--textColor);
  --mdc-typography-caption-font-size: 11px;
  --mdc-typography-caption-line-height: 8px;

  .mat-primary {
    --mat-full-pseudo-checkbox-unselected-icon-color: var(--borderColor);
  }
  .mat-warn {
    --mdc-text-button-label-text-color: var(--warning);
  }
  .mat-accent {
    --mdc-text-button-label-text-color: var(--success);
  }
}
.mat-mdc-menu-panel {
  box-shadow: var(--mat-menu-container-elevation-shadow);
}

.mat-elevation-z1 {
  @include mat.elevation(1);
}
.mat-elevation-z2 {
  @include mat.elevation(2);
}
.mat-elevation-z3 {
  @include mat.elevation(3);
}
.mat-mdc-radio-button .mdc-radio {
  width: var(--mdc-radio-state-layer-size);
  height: var(--mdc-radio-state-layer-size);
  .mat-mdc-radio-touch-target {
    width: var(--mdc-radio-state-layer-size);
    height: var(--mdc-radio-state-layer-size);
  }
}
.mdc-button--raised .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--muteText);
  margin-right: 1rem;
}

body .mat-mdc-icon-button {
  .mat-mdc-button-touch-target {
    width: var(--mdc-icon-button-state-layer-size, 48px);
    height: var(--mdc-icon-button-state-layer-size, 48px);
  }
}
